.content {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensure no scroll appears */
  background-color: #f2f2f2; /* Light background */
  padding: 50px; /* Remove padding to prevent overflow */
  margin: 0;
}

.buttons {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  margin-top: 20px;
  gap: 15px; /* Add space between buttons */
}

.location-button {
  background-color: #add8e6; /* Light grey for buttons */
  border: none;
  padding: 12px 24px;
  font-size: 18px; /* Slightly larger font size for buttons */
  border-radius: 8px; /* Softer round corners */
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-width: 150px; /* Uniform button size */
}

.location-button:hover {
  background-color: #b0bec5; /* Slightly darker on hover */
}
