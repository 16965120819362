.sidebar {
  background-color: #26587a;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%; /* Ensure it takes up the full height of the column */
}

.sidebar-top {
  background-color: #5fa0e5; /* Light blue section at the top */
  /* padding: 20px; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10%;
}

.location-icon {
  width: 50px; /* Adjust size */
  height: 50px; /* Adjust size */
  background-image: url('../images/LocationPin.png'); /* Replace with actual icon */
  background-size: cover;
}
