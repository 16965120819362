.topbar {
  width: 100%;
  height: 60px; /* Adjust height as needed */
  background-color: #fff; /* Or any other color */
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1201; /* Ensure it stays on top */
  padding: 0 16px; /* Optional padding */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  height: 40px; /* Adjust logo size */
}

.topbar-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
