@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

@font-face {
  font-family: 'PilatLight';
  src: url('./fonts/PilatLight.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'PilatWideHeavy';
  src: url('./fonts/PilatWideHeavy.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* div,
p,
div,
span {
  font-family: 'PilatLight';
} */

h2,
h3,
h4,
h5,
h6,
p,
span,
div,
a,
li,
ul,
ol,
figure,
figcaption,
blockquote,
code,
pre,
button,
input,
textarea,
label,
form,
table,
th,
td,
tr,
tbody,
thead,
tfoot {
  font-family: 'PilatLight' !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "PilatLight", "PilatWideHeavy"
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pilat-light {
  font-family: 'PilatLight', serif;
}
.pilat-wide-heavy {
  font-family: 'PilatWideHeavy', serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}